<!--
 * 
 *邀请三期 
-->

<template>
  <div>
    <div id="invite-activity">
      <!-- 固定顶部 -->
      <div class="fixedBox" :class="webBox ? 'appFixedBox' : !isApp ? 'appFixedTop' : ''">
        <!-- tabs -->
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.value"
            :class="item.value == activeTab ? 'activeTab' : ''"
            @click="inviteTabs(item.value)"
          >
            {{ item.name }}

            <div v-if="item.value == activeTab" class="tabLine"></div>
          </div>
        </div>
        <!-- 我的排名 -->
        <div v-if="activeTab == 2" class="myRankBox">
          <div class="myRank">
            <div class="myNumber">
              {{ isLogin ? (myTop.rank_status ? myTop.rank_num : '100+') : ' - ' }}
            </div>
            <div class="infoBox">
              <div class="myuserInfo">
                <div class="name">
                  <div>我</div>
                </div>
                <div>
                  <!-- <van-icon name="warning-o" @click="popShow = true" /> -->
                  已邀请<span class="inviteNum">{{ myTop.invite_total }}</span
                  >人
                </div>
                <!-- <div v-if="myTop.rank_status || myTop.rank_num == 1"> -->
                <div v-if="myTop.rank_num !== 1 && isLogin">
                  <div v-if="myTop.rank_status">
                    距离上一名差<span class="inviteNum">{{ myTop.ahead_num }}</span
                    >个盲盒
                  </div>
                  <!-- <div v-if="!myTop.rank_status">
                  已邀请<span class="inviteNum">{{ myTop.invite_total }}</span
                  >人，未上榜
                </div> -->
                </div>
                <div v-if="!myTop.rank_status">未上榜</div>
              </div>
              <div class="rewardTip">
                {{ myTop.awards }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 活动信息 -->
      <div v-if="activeTab == 1" class="activityBox" :class="!isApp ? 'appTop' : ''">
        <div style="position: relative">
          <img :src="activityStatus == 1 ? acitveIng : activeOver" alt="" />
        </div>
        <img src="https://activity-resource.321mh.com/nft/notice/invitation_9-5.png" />
        <!-- <div class="allActivity" :class="!isApp ? 'allActivityTop' : ''" @click="toHistoryActivity">
        历史活动
      </div>

      <div
        class="allActivity winnerBtn"
        :class="!isApp ? 'winnerBtnTop' : ''"
        @click="toAwardList(5)"
      >
        总榜名单
      </div>
      <div
        class="allActivity winnerBtn2"
        :class="!isApp ? 'winnerBtn2Top' : ''"
        @click="toAwardList(4)"
      >
        单日榜名单
      </div> -->
      </div>

      <!-- 邀请榜单 -->
      <div
        v-if="activeTab == 2"
        class="rankBox"
        :class="!isApp ? (myTop.awards ? 'appRankTopReward' : 'appRankTop') : ''"
      >
        <div class="rankList">
          <div class="rankItem" v-for="(item, index) in topList" :key="index">
            <div class="userInfo">
              <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                {{ index + 1 }}
              </div>
              <!-- <div class="headImg">
								<img src="../imgs/comm/pic_avatar_login.png" alt="" srcset="">
							</div> -->
              <div class="name">
                <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
              </div>
            </div>

            <!-- <div>
            邀请<span class="inviteNum">{{ item.invite_total }}</span
            >人
          </div> -->
          </div>

          <div class="rankTip">榜单仅显示前100名</div>
        </div>
      </div>

      <div v-if="activeTab == 3">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="list.length ? '-到底啦-' : ''"
          offset="200"
          class="recordList"
          :class="!isApp ? 'appTop' : ''"
          @load="onLoad"
        >
          <div class="recordItem" v-for="item in list" :key="item.id">
            <div class="name">
              <div>{{ item.user_name }}</div>
              <!-- <div>ID：{{ item.receive_user_id }}</div> -->
            </div>

            <div class="success">
              <!-- invite_status 1:已注册，2：已认证，3：邀请成功（购买了本期新人盲盒 -->
              <div v-if="!item.buy_count">
                <div v-if="item.invite_status === 1">已注册</div>
                <div v-if="item.invite_status === 2">已认证</div>
                <div v-if="item.invite_status === 3">邀请成功</div>
              </div>
              <div v-else>
                <div>已购{{ item.buy_count }}份</div>
              </div>

              <div v-if="item.id_verify === 1 && item.is_presale == 1">
                {{ getTime(item.presale_time) }}
              </div>
              <div v-if="item.id_verify === 1 && item.is_presale == 0">
                {{ getTime(item.verify_time) }}
              </div>
              <div v-if="item.id_verify === 0">{{ getTime(item.created_time) }}</div>
              <div></div>
            </div>
          </div>
        </van-list>
        <div class="space-div"></div>

        <div v-if="isLoading" class="dataNullBox">
          <div>
            <img
              src="@/imgs/loading/loading.gif"
              style="width: 2rem; height: 2rem"
              alt=""
              srcset=""
            />
          </div>
          <div class="text">数据加载中……</div>
        </div>
        <div v-else-if="isLoadError" class="dataNullBox">
          <div>
            <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
          </div>
          <div class="text" @click="getInviteLog">数据加载失败，请检查网络设置后刷新页面</div>
        </div>
        <div v-else-if="!list.length" class="dataNullBox">
          <div>
            <img src="@/imgs/comm/dataNull.png" alt="" srcset="" />
          </div>
          <div class="text">暂无邀请记录</div>
        </div>
      </div>

      <!-- 底部遮罩 -->
      <div class="footer">
        <!-- <div class="inviteTips" v-if="isLogin && myTop.invite_total < 40">
        {{ myTop.invite_total < 5 ? '邀请' : '再邀请'
        }}<span class="num">{{ userInviteInfo.need_num }}</span
        >人 {{ myTop.invite_total < 5 ? '即可获得' : '可再获得' }}{{ userInviteInfo.award_name }}*{{
          userInviteInfo.award_num
        }}份
      </div> -->

        <!-- 这三个按钮在活动进行中显示 -->
        <div class="shareBtnBox">
          <div class="posterBtn" @click="qrcodeDom">生成专属邀请海报</div>
          <div class="shareBtn" @click="openCopytextPop">生成专属邀请文本</div>
          <div class="shareBtn doubleText" @click="copyCode">
            <div>复制邀请码</div>
            <div class="myCode">我的邀请码：{{ isLogin ? myInviteCode : '--' }}</div>
          </div>
        </div>

        <!-- 海报 -->
        <div style="position: fixed; top: -250%; font-size: 0">
          <div id="testPost">
            <img class="posterBg" :src="posterBg" />
            <!-- <div id="qrcode" class="qrcodeImg"></div> -->
            <!-- <img class="qrcodeImg" :src="qrcodeImg" alt="" srcset="" /> -->
            <vueQr
              class="qrcodeImgStyle"
              :text="qrcodeImg"
              :margin="0"
              :logoSrc="'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1654699036635.jpg'"
            >
            </vueQr>
          </div>
        </div>

        <div class="getMoneyBox"></div>
      </div>

      <van-popup class="vantPop" v-model:show="posterPop">
        <div class="poster">
          <!-- <van-image width="100" height="100" :src="posterImg" /> -->
          <img style="pointer-events: auto !important" :src="posterImg" alt="" srcset="" />

          <div v-if="posterImg" class="saveTip">长按图片保存</div>
        </div>
      </van-popup>

      <PageLoading :show="pageLoading" />

      <!-- 说明弹窗 -->
      <van-popup class="vantPop" v-model:show="popShow">
        <div class="popup">
          <div class="content">
            <div>邀请人数相同，则按完成时间先后进行排名。</div>
          </div>
          <div class="btn">
            <div @click="popShow = false">知道了</div>
          </div>
        </div>
      </van-popup>

      <!-- 复制文本弹窗 -->
      <van-popup class="vantPop" v-model:show="copytextPop">
        <div class="popup">
          <div class="copytextPop">
            <div class="title">文本已复制</div>
            <div class="tip">打开第三方软件粘贴给好友</div>
            <div class="copyContent">
              <div>{{ shareText }}</div>
              <!-- <div>https://collect.mh51.com/xxxxxxx</div> -->
              <div style="color: #3cdbe8; font-weight: 500" @click="copyText">复制文本</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  getuserinvitationlog,
  getactivityinfo,
  withdraw,
  withdrawways,
  getawardrollnotice,
  getuserinvitenotice,
  getinvitebuycountrank
} from '@/service/invite';
import { getUserCollection } from '@/service/user';
import { nftUtils } from '@/utils';
import moment from 'moment';
import useClipboard from 'vue-clipboard3';
import domtoimage from 'dom-to-image';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import upload from '@/utils/upload.js';
import PageLoading from '@/components/loading/PageLoading.vue';
export default {
  name: 'invite5',
  components: {
    PageLoading,
    vueQr
  },
  watch: {
    '$store.state.webBox'(newVal, oldVal) {
      if (newVal != oldVal) {
        this.webBox = newVal;
      }
    }
  },
  setup() {
    const toastFn = inject('$toast');
    const { toClipboard } = useClipboard();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      pageLoading: false,
      posterImg: '', // 海报图片
      isFlag: true, // 用于处理ios需要点击两次的问题 （后期改成http链接可能不需要）
      qrcodeImg: window.location.origin + '/login', // 二维码图片
      posterPop: false, // 弹窗显示
      activityId: 17,
      webBox: store.state.webBox,
      acitveIng: 'https://activity-resource.321mh.com/nft/notice/invitation_start_9-5.png', //活动中图片头部
      activeOver: 'https://activity-resource.321mh.com/nft/notice/invitation_end_9-5.png', //活动结束头图
      tabs: [
        {
          value: 1,
          name: '活动信息'
        },
        {
          value: 2,
          name: '邀请榜单'
        },
        {
          value: 3,
          name: '邀请记录'
        }
      ],
      activeTab: 1,
      popShow: false,
      topList: [],
      myTop: {},
      myTopItem: null,
      startTime: '',
      getMoneyPop: false,
      labelWidth: 40,
      copytextPop: false,
      posterBg: require('../../imgs/poster/invite5.png'),
      myInviteCode: null,
      list: [],
      finished: false,
      page: 1,
      loading: true,
      dest_account: '',
      dest_name: '',
      activityStatus: 1,
      shareText: '',
      idName: null,
      carveupStatus: null,
      carveupPrice: null,
      isApp: nftUtils.isInApp(),
      isLogin: nftUtils.isLogin(),
      getMoneyBankPop: false,
      bankBankForm: {
        myMobile: '',
        dest_account: '',
        dest_name: '',
        withdraw_bank: '', //开户银行
        withdraw_sub_bank: '', //开户支行
        account_addr: '', //开户地
        reserved_mobile: '' //预留手机号
      },
      labelBankWidth: 75,
      withdrawType: 1,
      noticeSwiper: [], // 公告通知数据
      userInviteInfo: {}, // 底部邀请文案情况数据
      isLoading: false,
      isLoadError: false
    });

    // 去榜单里面找出我的排名，如果不再榜单内则显示 -
    const myTopNum = id => {
      let myTopNum = '-';
      for (let i in state.topList) {
        if (state.topList[i].user_id === id) {
          myTopNum = ++i;
        }
      }
      return myTopNum;
    };

    const inviteTabs = value => {
      state.activeTab = value;
      if (value == 1) {
        nftUtils.setcnzzRecordByBtn('活动信息');
      } else if (value == 2) {
        nftUtils.setcnzzRecordByBtn('邀请榜单');
      } else if (value == 3) {
        nftUtils.setcnzzRecordByBtn('邀请记录');
      }
    };

    // 获取榜单列表
    const getTopFn = () => {
      getinvitebuycountrank({
        size: 100,
        act_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.topList = res.data.rank;
          state.myTop = res.data.user;
        }
      });
    };

    // 复制邀请链接
    const copyText = async () => {
      nftUtils.setcnzzRecordByBtn('复制文本');
      try {
        await toClipboard(state.shareText);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    const openCopytextPop = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      const shareUrl =
        window.location.origin +
        '/login?invite_user_id=' +
        state.myInviteCode +
        '&activityId=' +
        state.activityId;
      state.shareText =
        '邀请您加入看漫数藏，小明太极顶部动漫IP系列《盖世帝尊》·灵宠盲盒开售，参与就有机会拿千元大奖、创世勋章！详情点击' +
        shareUrl;
      console.log(nftUtils.isSupprotNftShare());
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftShareText(state.shareText);
      } else {
        state.copytextPop = true;
        try {
          await toClipboard(state.shareText);
        } catch (e) {
          console.log(e);
        }
      }
    };

    // 复制邀请码
    const copyCode = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      nftUtils.setcnzzRecordByBtn('复制邀请码');
      try {
        await toClipboard(state.myInviteCode);
        console.log('复制成功!');
        toastFn({ message: '复制成功！' });
      } catch (e) {
        console.log(e);
      }
    };

    // 跳转历史活动页面
    const toHistoryActivity = () => {
      nftUtils.setcnzzRecordByBtn('跳转历史活动页面');
      router.push({
        path: '/historyActivity'
      });
    };

    // 获取邀请记录
    const getInviteLog = () => {
      if (!state.isLogin) {
        state.loading = false;
        return;
      }
      if (state.page === 1) {
        state.isLoadError = false;
        state.isLoading = true;
      }
      getuserinvitationlog({
        page: state.page,
        page_size: 10,
        // act_id: 1
        act_id: state.activityId
      })
        .then(res => {
          console.log(res);
          if (res.status === 0) {
            state.pageLoading = false;
            state.list = state.list.concat(res.data || []);

            state.loading = false;
            if (!res.data || !res.data.length) {
              state.finished = true;
            }
            if (res.data && res.data.length < 10) {
              state.finished = true;
            }
            if (state.page === 1) {
              state.isLoadError = false;
              state.isLoading = false;
            }
          }
        })
        .catch(err => {
          if (state.page === 1) {
            state.loading = false;
            state.finished = true;
            state.isLoading = false;
            state.isLoadError = true;
          }
        });
    };

    // 加载更多
    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getInviteLog();
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    // 获取活动信息（用于判断按钮状态）
    const getInfo = () => {
      getactivityinfo({
        activity_id: state.activityId
      }).then(res => {
        console.log('活动信息', res);
        state.activityStatus = res.data.status;
        state.carveupStatus = res.data.carveup_status;
        state.carveupPrice = res.data.carveup_price;
      });
    };

    // 打开领取现金弹窗
    const openGetMoneyPop = async () => {
      // nftUtils.setcnzzRecordByBtn('打开领取现金弹窗');
      const { data, status } = await getUserCollection();
      if (data && status === 0) {
        console.log(data);
        if (!data.id_name) {
          router.push({ path: '/authentication' });
        } else {
          state.dest_name = formatName(data.id_name);
          state.dest_account = data.mobile;
          state.idName = data.id_name;
          state.bankBankForm = {
            myMobile: data.mobile,
            dest_account: '',
            dest_name: formatName(data.id_name),
            withdraw_bank: '', //开户银行
            withdraw_sub_bank: '', //开户支行
            account_addr: '', //开户地
            reserved_mobile: '' //预留手机号
          };

          withdrawways({}).then(res => {
            console.log(res);
            state.withdrawType = res.data.withdraw_way;
            if (res.data.withdraw_way == 1) {
              state.getMoneyPop = true;
              // state.getMoneyBankPop = true;
            } else {
              state.getMoneyBankPop = true;
            }
          });
        }
      }
    };

    // 确认领取现金
    const submitGet = () => {
      if (state.dest_account.length == 11) {
        withdraw({
          activity_id: state.activityId,
          withdraw_way: 1,
          dest_account: state.dest_account,
          dest_name: state.idName
        }).then(res => {
          state.getMoneyPop = false;
          getInfo();
          if (res.status === 0) {
            toastFn({ message: '领取成功' });
            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else {
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      }
    };

    const submitGetBank = () => {
      if (
        state.dest_account.length == 11 &&
        state.bankBankForm.dest_account &&
        state.bankBankForm.withdraw_bank &&
        state.bankBankForm.account_addr &&
        state.bankBankForm.reserved_mobile
      ) {
        withdraw({
          activity_id: state.activityId,
          withdraw_way: 2,
          dest_name: state.idName,

          dest_account: state.bankBankForm.dest_account,
          withdraw_bank: state.bankBankForm.withdraw_bank, //开户银行
          withdraw_sub_bank: state.bankBankForm.withdraw_sub_bank, //开户支行
          account_addr: state.bankBankForm.account_addr, //开户地
          reserved_mobile: state.bankBankForm.reserved_mobile //预留手机号
        }).then(res => {
          state.getMoneyBankPop = false;
          getInfo();
          if (res.status === 0) {
            toastFn({ message: '已提交领取' });
            nftUtils.setcnzzRecordByBtn('成功领取现金');
          } else {
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      } else {
        toastFn({ message: '请填写所有信息', duration: 3000 });
      }
    };

    // 名字第一个字替换成*
    function formatName(str) {
      return new Array(str.length - 1).join('*') + str.substr(-2);
    }

    // 生成二维码
    const qrcodeDom = async () => {
      if (!state.isLogin) {
        router.push({ path: '/authentication' });
        return;
      }
      // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      state.pageLoading = true;
      nftUtils.setcnzzRecordByBtn('生成专属邀请海报');

      if (store.state.posterUrl) {
        // 上传过后不用生成和上传了
        havePosterUrlToPoster();
      } else {
        uploadDomToPoster(nftUtils.isIOS());
      }
    };
    // 直接转为Blob生成并上传的oss，iOS特殊处理，需要执行两次toBlob
    const uploadDomToPoster = isIos => {
      if (!store.state.posterUrl) {
        if (isIos) {
          domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
            updateDomToPosterOnece();
          });
        } else {
          updateDomToPosterOnece();
        }
      } else {
        havePosterUrlToPoster();
      }
    };

    const havePosterUrlToPoster = () => {
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftSharePic(store.state.posterUrl);
        state.pageLoading = false;
      } else {
        state.posterImg = store.state.posterUrl;
        state.posterPop = true;
        state.pageLoading = false;
      }
    };

    // 直接转为toBlob，首次进入时两次生成并上传oss显示，第二次及以后，只需一次生成并上传oss显示
    const updateDomToPosterOnece = () => {
      domtoimage.toBlob(document.getElementById('testPost'), { quality: 1 }).then(dataUrl => {
        if (nftUtils.isSupprotNftShare()) {
          upload.uploadHandle64(dataUrl, function (link) {
            nftUtils.nftSharePic(link);
            state.pageLoading = false;
            store.state.posterUrl = link;
          });
        } else {
          upload.uploadHandle64(dataUrl, function (link) {
            state.posterImg = link;
            state.posterPop = true;
            state.pageLoading = false;
            store.state.posterUrl = link;
          });
        }
      });
    };

    // 计算与当前时间时间差(没用到)
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const toAwardList = id => {
      router.push({
        path: '/announcement/detail',
        query: { id }
      });
      nftUtils.setcnzzRecordByBtn('获奖名单');
    };

    // 获取通知滚动数据
    const getNotice = () => {
      getawardrollnotice({
        act_id: state.activityId
      }).then(res => {
        if (res.status == 0) {
          state.noticeSwiper = res.data;
        }
      });
    };

    // 用户邀请信息
    const getUserInvite = () => {
      getuserinvitenotice({
        act_id: state.activityId
      }).then(res => {
        if (res.status == 0) {
          state.userInviteInfo = res.data;
        }
      });
    };

    // 拼接二维码链接
    const setPosterImg = () => {
      let url = window.location;
      if (!window.location.origin) {
        url =
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');
      } else {
        url = window.location.origin;
      }

      if (url.indexOf('mh51') > -1) {
        url = 'https://collect.yx03.com';
        // url = url.replace('https://collect.mh51.com/', 'https://collect.yx03.com/');
      }
      state.qrcodeImg =
        url +
        '/login?invite_user_id=' +
        store.state.loginInfo.my_code +
        '&activityId=' +
        state.activityId;
    };

    onMounted(async () => {
      if (state.isLogin) {
        getUserInvite();

        await store.dispatch('refreshLoginInfo');
        console.log('store', store);
        setPosterImg();
        if (store.state.loginInfo) {
          state.myInviteCode = store.state.loginInfo.my_code;
        }
      }
      getNotice();
      getTopFn();
      getInfo();

      nftUtils.setcnzzRecordByBtn('进入邀请有奖页面');
    });

    return {
      ...toRefs(state),
      notice: require('../../imgs/icon/notice.png'),
      myTopNum,
      inviteTabs,
      copyText,
      getTopFn,
      copyCode,
      toHistoryActivity,
      onLoad,
      getTime,
      openGetMoneyPop,
      submitGet,
      openCopytextPop,
      qrcodeDom,
      diffTime,
      toAwardList,
      submitGetBank,
      getInviteLog
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#invite-activity {
  // background: #05082f;

  .space-div {
    height: 130px;
    width: 100%;
  }
  .fixedBox {
    position: fixed;
    z-index: 10;
    background: linear-gradient(180deg, #272827 0%, #0c0d0c 49%, #1d221e 100%);
    width: 100%;
    top: 0;
    left: 0;
  }

  .appFixedTop {
    padding-top: 30px;
  }

  .appFixedBox {
    padding-top: 80px;
  }

  .tabs {
    display: flex;
    justify-content: center;
    line-height: 40px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    .activeTab {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 0 18px;
      position: relative;

      .tabLine {
        height: 3px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
      }
    }
  }

  .activityBox {
    // position: relative;
    padding: 54px 0 110px 0;
    text-align: center;
    color: #ffffff;
    // background: #05082f;
    font-size: 0px;

    .noticeBar {
      width: 100%;

      --van-notice-bar-icon-size: 23px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      .vanNotice {
        padding: 0 20px;
      }

      .van-notice-bar {
        height: 34px;
        border-radius: 23px;
      }
    }

    .notice-swipe {
      height: 34px;
      line-height: 34px;
    }

    img {
      width: 100%;
    }

    .allActivity {
      position: fixed;
      right: 0;
      top: 100px;
      width: 71px;
      height: 28px;
      background: #58dedd;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      border-radius: 14px 0 0 14px;
    }

    .allActivityTop {
      top: 170px;
    }

    .winnerBtn {
      top: 140px;
    }
    .winnerBtn2 {
      top: 180px;
    }
    .winnerBtn2Top {
      top: 250px;
    }

    .winnerBtnTop {
      top: 210px;
    }
  }

  .rankBox {
    padding: 119px 20px 0 20px;
    // background: #000000;
  }

  .rankList {
    padding-bottom: 130px;

    .rankTip {
      text-align: center;
      padding: 25px 0 0 0;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .rankItem {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;

    .userInfo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .number {
      color: rgba(255, 255, 255, 1);
      width: 28px;
      height: 28px;
      background: rgb(54, 51, 64);
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
    }

    .headImg {
      margin-left: 20px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #3cdbe8;
        object-fit: cover;
      }
    }

    .name {
      margin-left: 16px;

      :first-child {
        font-size: 14px;
        font-weight: 600;
      }

      :last-child {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .inviteNum {
      color: #3cdbe8;
    }
  }

  .myRankBox {
    background: #2f293f;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 400;

    .myRank {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
    }

    .rewardTip {
      width: 100%;
      white-space: nowrap;
      font-size: 11px;
      transform: scale(0.75);
      letter-spacing: -1px;
      transform-origin: left;
      font-weight: 400;
      color: #ffffff;
    }

    .infoBox {
      width: 100%;
    }

    .myuserInfo {
      width: 290px;
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      align-items: center;
    }

    .myNumber {
      width: 32px;
      height: 32px;
      margin-right: 15px;
      line-height: 32px;
      background: #fff;
      color: #05082f;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      border-radius: 50%;
      flex: none;
    }

    .inviteNum {
      color: #3cdbe8;
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 110px;
    background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);

    .inviteTips {
      position: absolute;
      bottom: 70px;
      left: 0;
      text-align: center;
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;

      .num {
        color: #3cdbe8;
      }
    }

    .shareBtnBox {
      position: absolute;
      bottom: 84px;
      width: 335px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
    }

    .otherStatusBtn {
      // width: 100%;
      // display: flex;
      // justify-content: space-around;
      position: absolute;
      bottom: 72px;
      left: 50%;
      transform: translateX(-50%);

      > div {
        width: 335px;
        height: 36px;
        line-height: 36px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: #1c172a;
        background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
        // background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        border-radius: 4px 4px 4px 4px;
      }

      .getBtn {
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      }
    }

    .doubleText {
      line-height: 13px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .myCode {
      font-size: 8px;
      font-weight: 100;
      color: #1c172a;
    }

    .getMoneyBox {
      position: absolute;
      bottom: 24px;
      width: 100%;
      left: 0;
    }
  }

  .popup {
    width: 300px;
    background: #2f293f;
    border-radius: 8px 8px 8px 8px;
    text-align: left;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

    .content {
      padding: 30px 16px;
      line-height: 26px;
    }

    .btn {
      div {
        text-align: center;
        line-height: 50px;
        font-size: 16px;
        border-top: 1px solid #494359;
      }
    }
  }

  .getMoneyPopStyle {
    text-align: center;
    padding: 32px 0 24px 0;
    .input {
      background: #1c172a;
      border-radius: 8px;
      color: white;
      margin: 20px 0;
      font-size: 14px;
      font-weight: 400;
    }

    .title {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
    }

    .tip {
      margin-top: 5px;
      color: rgba(255, 255, 255, 0.7);
    }

    .inputBox {
      padding: 0 20px;
    }

    .submitGet {
      width: 116px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin: 0 auto;
      color: #1c172a;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      border-radius: 8px 8px 8px 8px;
    }

    .disSubmitGet {
      background: linear-gradient(132deg, #5b8d80 0%, #248795 100%);
    }
  }

  .copytextPop {
    padding: 24px 20px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;

    .title {
      font-size: 18px;
      font-weight: 500;
    }

    .tip {
      margin-top: 2px;
      color: rgba(255, 255, 255, 0.7);
    }

    .copyContent {
      margin-top: 16px;
      background: #1c172a;
      border-radius: 4px 4px 4px 4px;
      padding: 12px;

      line-height: 22px;
    }
  }

  .recordList {
    padding: 0 20px;
    padding-top: 54px;
    .recordItem {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 400;
      color: #fff;
      margin-top: 20px;

      .name {
        :first-child {
          font-size: 14px;
        }

        :last-child {
          margin-top: 6px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .success {
        text-align: right;

        :first-child {
          font-weight: 600;
        }

        :last-child {
          margin-top: 6px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .dataNullBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      width: 150px;
      height: 110px;
    }

    .text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .appTop {
    padding-top: 84px;
  }

  .appRankTop {
    padding-top: 132px;
  }

  .appRankTopReward {
    padding-top: 149px;
  }
}

.disPosterBtn {
  opacity: 0.6;
  font-size: 16px;
}
#testPost {
  position: relative;
  display: inline-block;
  .posterBg {
    // width: 616px;
    height: 1070px;
    // width: 308px;
    // height: 560px;
  }

  .qrcodeImgStyle {
    width: 120px;
    height: 120px;
    // width: 60px;
    // height: 60px;
    position: absolute;
    left: 71px;
    bottom: 100px;
    // left: 26px;
    // bottom: 44px;
    // transform: translateX(-50%);
    // top: 352px;
    border: 4px solid;
    border-radius: 4px;
    border-image: linear-gradient(180deg, rgba(203, 255, 227, 1), rgba(112, 204, 217, 1)) 4 4;
  }
}

.poster {
  width: 308px;

  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 100%;
  }
}
</style>
<style lang="scss">
#invite-activity {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }

  .shareBtn,
  .posterBtn {
    text-align: center;
    width: 105px;
    height: 36px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 11px;
    font-weight: 600;
    line-height: 36px;
    color: #1c172a;
  }
}
</style>
